// Supports weights 100-900
import '@fontsource-variable/inter';

import * as React from 'react';
import ThemeContextProvider from './src/context/theme-context';
import { AnimatePresence } from "framer-motion";


export const wrapRootElement = ({ element }) => <ThemeContextProvider>{element}</ThemeContextProvider>;

export const wrapPageElement = ({ element }) => <AnimatePresence mode="wait">{element}</AnimatePresence>;