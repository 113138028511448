import * as React from "react";

export const ThemeContext = React.createContext([]);

const ThemeContextProvider = ({ children }) => {
    // We can pass a function to useState. This is useful if initial state is the result of an expensive computation (here we're retrieving inital state from localStorage). The passed function will be executed only once on the initial render. And the retrieved state will be used during that initial render only. In subsequent renders, it will be disregarded. This concept is called lazy initial state

    const [darkTheme, setDarkTheme] = React.useState(() => {
        const theme = localStorage.getItem("ds_blog_theme");
        // Validate the value to ensure it's either true or false and prevent unauthorized changes
        if (theme === "true" || theme === "false") return JSON.parse(theme);
        else return false;
    });

    return <ThemeContext.Provider value={[darkTheme, setDarkTheme]}>{children}</ThemeContext.Provider>;
};

export default ThemeContextProvider;